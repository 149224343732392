(function($) {
    $(document).ready(function() {

        var prevArrow = '<button class="slick-prev slick-arrow" aria-label="Previous" type="button"><svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.42 22.24"><defs><style>.cls-1{isolation:isolate;}.cls-2{fill:#295227;stroke-width:0px;}</style></defs><g id="_" class="cls-1"><g class="cls-1"><path class="cls-2" d="m10.38,0l2.12,2.12-5.31,8.85,5.25,8.97-2.12,2.3L0,10.97,10.38,0Zm10.92,0l2.12,2.12-5.31,8.85,5.25,8.97-2.12,2.3-10.33-11.27L21.3,0Z"/></g></g></svg></button>';
        var nextArrow = '<button class="slick-next slick-arrow" aria-label="Next" type="button"><svg width="24" height="24" viewBox="0 0 23.42 22.24" xmlns="http://www.w3.org/2000/svg"><path d="m13.04 22.24-2.12-2.12 5.31-8.85-5.26-8.97 2.12-2.3 10.32 11.27-10.38 10.97zm-10.92 0-2.12-2.12 5.31-8.85-5.25-8.97 2.12-2.3 10.33 11.27z" fill="#295227"/></svg></button>';

        $('.services-slider').slick({
            mobileFirst: true,
            arrows: true,
            dots: true,
            prevArrow: prevArrow,
            nextArrow: nextArrow,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        dots: false,
                    }
                },
            ],
        });

        $('.gallery-slider').slick({
            arrows: true,
            dots: false,
            prevArrow: prevArrow,
            nextArrow: nextArrow,
        });

        $('.testimonials-slider').slick({
            arrows: false,
            dots: true,
            adaptiveHeight: true,
            prevArrow: prevArrow,
            nextArrow: nextArrow,
        });

    });
})(jQuery);